<template>
  <Layout :tituloPagina="`Productos | Categorías | ${modo == 'nueva' ? 'Nueva' : 'Edición de'} categoría`">
    <div class="row">
      <div class="col-lg-7">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{ modo == 'nueva' ? 'Nueva' : 'Edición de' }} categoría
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nueva' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <label class="col-md-4">Foto</label>
              <div class="col-md-4 text-center">
                <div id="marcoFoto">
                  <div
                    id="divBtnFoto"
                    @click="agregarArchivo()"
                    v-if="srcFoto == ''"
                  >
                    {{ srcFoto == '' ? 'Seleccionar imagen' : '' }}
                  </div>
                  <div v-if="srcFoto != ''">
                    <button
                      type="button"
                      @click="eliminarArchivo()"
                      class="btnEliminarFoto btn btn-danger btn-sm btn-rounded"
                    >
                      <i class="mdi mdi-close-thick"></i>
                    </button>
                    <img id="imagenCategoria" :src="srcFoto" class="iconcategoria"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Nombre</label>
                <input
                  ref="nombre"
                  class="form-control"
                  name="nombre"
                  placeholder="Nombre de la categoría"
                  v-model="categoria.nombre"
                  :disabled="categoria.generica"
                />
              </div>
              <div class="col-md-3">
                <label>Tipo</label>
                <select
                  class="form-select"
                  name="servicios"
                  v-model="categoria.servicios"
                  @change="actualizarBanderaServicioRecurrente()"
                  :disabled="categoria.generica"
                >
                  <option :value="0">Productos</option>
                  <option :value="1">Servicios</option>
                </select>
              </div>
              <div
                class="divServiciosRecurrentes col-md-3"
                v-show="categoria.servicios == 1"
              >
                <label>¿Es recurrente?</label>
                <select
                  class="form-select"
                  name="servicios_recurrentes"
                  v-model="categoria.servicios_recurrentes"
                  :disabled="categoria.generica"
                >
                  <option :value="0">No</option>
                  <option :value="1">Si</option>
                </select>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-12">
                <label>Descripción</label>
                <textarea
                  class="form-control"
                  placeholder="Descripción de la categoría"
                  rows="5"
                  name="descripcion"
                  v-model="categoria.descripcion"
                  :disabled="categoria.generica"
                ></textarea>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <label>
                  Los <span v-show="!categoria.servicios">productos</span>
                  <span v-show="categoria.servicios">servicios</span>
                  de esta categoría necesitarán ser relacionados con ...
                </label>

                <br />
                <div class="btn-group">
                  <button
                    class="btn btn-sm"
                    @click="necesita('nada')"
                    :class="{
                      'btn-success': !categoria.necesita_cpe && !categoria.necesita_dh,
                      'btn-soft-success': categoria.necesita_cpe || categoria.necesita_dh
                    }"
                    :disabled="categoria.generica"
                  >
                    nada
                  </button>

                  <button
                    class="btn btn-sm"
                    @click="necesita('cpe')"
                    :class="{
                      'btn-success': categoria.necesita_cpe,
                      'btn-soft-success': !categoria.necesita_cpe
                    }"
                    :disabled="categoria.generica"
                  >
                    un CPE
                  </button>

                  <button
                    class="btn btn-sm"
                    @click="necesita('dh')"
                    :class="{
                      'btn-success': categoria.necesita_dh,
                      'btn-soft-success': !categoria.necesita_dh
                    }"
                    :disabled="categoria.generica"
                  >
                    un dispositivo para hogar
                  </button>
                </div>
              </div>
            </div>

            <br />

            <div class="row">
              <div class="col-md-10">
                <label>
                  ¿A los <span class="spnTipoProducto">productos</span>
                  de esta categoría se les necesitará agregar un perfil de
                  velocidad?
                </label>
              </div>
              <div class="col-md-2">
                <select
                  class="form-select"
                  name="necesita_perfil_velocidad_cpes"
                  v-model="categoria.necesita_perfil_velocidad_cpes"
                  :disabled="categoria.generica"
                >
                  <option :value="0">No</option>
                  <option :value="1">Si</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="cancelar()">
              <i class="mdi mdi-chevron-left"></i>
              Atrás
            </button>
            <button
              class="btn btn-success"
              @click="modo == 'nueva' ? guardar() : actualizar()"
              v-if="modo == 'nueva' || categoria.generica == false"
              :disabled="bandera_spinner"
            >
              <i 
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{ modo == 'nueva' ? 'Guardar' : 'Actualizar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import API from '@/API.js'
import CategoriaProductosSrv from '@/services/CategoriaProductosSrv.js'
export default {
  name: 'EdicionCategoriaProductos',
  props: ['id'],
  components: { Layout },
  data() {
    return {
      categoria: {
        id: null,
        nombre: '',
        descripcion: '',
        id_foto: null,
        necesita_cpe: false,
        necesita_dh: false,
        necesita_perfil_velocidad_cpes: 0,
        servicios_recurrentes: 0,
        servicios: 0,
        _eliminarFotoAntigua: false,
        generica: false
      },
      srcFoto: '',
      modo: 'nueva',
      bandera_spinner: false
    }
  },

  created: function() {
    // Detección del modo
    if (this.$route.path.indexOf('nueva') >= 0) this.modo = 'nueva'
    else this.modo = 'edicion'

    // Cargas iniciales
    if (this.modo == 'edicion') {
      this.cargarCategoria()
    }
  },

  methods: {
    actualizar: function() {
      var self = this
      var categoria = Object.assign({}, self.categoria)

      self.bandera_spinner = true

      if(categoria.familia == null) delete categoria.familia

      if (categoria.nombre == '') {
        iu.msg.warning('Es necesario un nombre para la categoría')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      CategoriaProductosSrv.actualizar(categoria).then(response => {
        iu.msg.success('Se actualizó correctamente')

        self.srcFoto = ''
        var inputs = document.getElementsByName("foto")
        if(inputs.length > 0) inputs[0].remove()

        self.cargarCategoria()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    actualizarBanderaServicioRecurrente: function() {
      if (this.categoria.servicios == 0)
        this.categoria.servicios_recurrentes = 0
    },
    agregarArchivo: function() {
      var self = this

      var marcoFoto = document.getElementById("marcoFoto")
      var input = document.createElement("input")
      input.setAttribute('type', 'file')
      input.setAttribute('name', 'foto')
      input.setAttribute('style', 'display: none')
      marcoFoto.append(input)

      input.click()
      input.addEventListener('change', function(e) {
        var input = e.target

        var reader = new FileReader()
        reader.onload = function(e1) {
          self.srcFoto = e1.target.result
        }

        if (input.files.length > 0) {
          reader.readAsDataURL(input.files[0])
        }
      })
    },

    cancelar: function() {
      this.$router.go(-1)
    },

    cargarCategoria: function() {
      var self = this

      var id = self.categoria.id || this.$route.params.id

      CategoriaProductosSrv.categoriaJSON(id).then(response => {
        let categoria = response.data
        self.categoria = categoria

        if (categoria.id_foto != null)
          self.srcFoto =
            API +
            '/productos/categorias/' +
            categoria.id +
            '/imagen?tipo_foto=256x256&_tk=' +
            localStorage.getItem('argusblack.token') +
            '&_tiempo=' +
            new Date().getTime() 
        else self.srcFoto = ''
      })
      .catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la categoría'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.cancelar()
    },

    eliminarArchivo: function() {
      var self = this

      self.categoria._eliminarFotoAntigua = true
      self.srcFoto = ''
      
      var inputs = document.getElementsByName("foto")
      if(inputs.length > 0) inputs[0].remove()
    },

    guardar: function() {
      var self = this
      var categoria = Object.assign({}, self.categoria)

      self.bandera_spinner = true

      // Verificación de datos
      if (categoria.nombre == '') {
        iu.msg.warning('Es necesario un nombre para la categoría')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      CategoriaProductosSrv.guardar(categoria).then(response => {
        iu.msg.success('Se guardó correctamente')

        var id = response.data

        self.$router.replace({
          name: 'edicionCategoriaProductos',
          params: {
            id: id,
            nuevaCategoria: true
          }
        })

        self.srcFoto = ''
        var inputs = document.getElementsByName("foto")
        if(inputs.length > 0) inputs[0].remove()

        self.categoria.id = id
        self.modo = 'edicion'

        self.cargarCategoria()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    necesita: function(opcion) {
      var self = this

      switch (opcion) {
        case 'nada':
          self.categoria.necesita_cpe = false
          self.categoria.necesita_dh = false
          break
        case 'cpe':
          self.categoria.necesita_cpe = true
          self.categoria.necesita_dh = false
          break
        case 'dh':
          self.categoria.necesita_cpe = false
          self.categoria.necesita_dh = true
          break
      }
    }
  },
  mounted() {}
}
</script>

<style scoped>
input[type='file'] {
  display: none;
}
#marcoFoto {
  border: thin solid #ccc;
  border-radius: 0.75rem;
  background-color: #fafafa;
  width: 200px;
  height: 200px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
#divBtnFoto {
  width: 100%;
  height: 100%;
  line-height: 200px;
}
.btnEliminarFoto {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 100;
}
.iconcategoria{
  width:200px;
  height:200px;
}
</style>